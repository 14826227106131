
import { Component, Vue, Prop } from 'vue-property-decorator';
import Pagination from '@/components/Pagination/index.vue';
import { getReservationClientList, cancelReservationClient } from '@/api/reservationClient';
import moment from 'moment';
import Calendar from '@/components/Calendar/index.vue';
import { getAiStoreInfoList } from '@/api/storeInfo';
import { getIndustryList } from '@/api/industry';
import {
  getLat,
  getLng,
  setLat,
  setLng,
} from '@/utils/cookies';

@Component({
  components: {
    Pagination,
    Calendar,
  },
})

export default class extends Vue {
  @Prop({ required: true }) private status!: Number;

  mounted() {
    this.getReservationList();
    this.getIndustryList();
  }

  private lastRandomIndexes: Record<number, number> = {};

  private randomIdx(idx: number): number {
    if (this.lastRandomIndexes[idx] !== undefined) {
      return this.lastRandomIndexes[idx];
    }
    const randomIndex = Math.floor(Math.random() * this.aiItem.placeList.length);
    this.lastRandomIndexes[idx] = randomIndex;
    return randomIndex;
  }

  private restaurantrandomIdx(idx: number): number {
    if (this.lastRandomIndexes[idx] !== undefined) {
      return this.lastRandomIndexes[idx];
    }
    const randomIndex = Math.floor(Math.random() * this.aiItem.restaurantList.length);
    this.lastRandomIndexes[idx] = randomIndex;
    return randomIndex;
  }

  private apiUrl = process.env.VUE_APP_COMMON_API;

  private loading = true;

  private dateRange = [];

  private filterSortDrawer = false;

  private categorySortDrawer = false;

  private listQuery: any = {
    searchValue: '',
    industryIdx: null,
    page: 1,
    size: 5,
    region: '',
    lat: getLat(),
    lng: getLng(),
    adult: 1,
    child: 0,
    status: this.status,
    tabName: '',

    useStart: this.$route.query.useStart ? this.$route.query.useStart : moment().startOf('year').format('YYYY-MM-DD'),
    useEnd: this.$route.query.useEnd ? this.$route.query.useEnd : moment().endOf('year').format('YYYY-MM-DD'),
    sort: 'useDate',
  }

  private sortList = [
    { label: '최신순', value: 'useDate' },
    { label: '과거순', value: 'pastDate' },
  ]

  private industryList = [];

  private randomIndex = 0;

  private getIndustryList() {
    this.loading = true;
    getIndustryList().then((res) => {
      this.loading = false;
      this.industryList = res.data;
      this.industryList.forEach((item: any, index: any) => {
        if (item.name === '일반상품') {
          this.industryList.splice(index, 1);
        }
        if (item.name === '레저') {
          this.industryList.splice(index, 1);
        }
        if (item.name === '체험') {
          item.name = '레저 / 체험';
        }
      });
    });
  }

  private searchRange = [];

  private totalElements = 0;

  private clientList = [];

  private cancelyn = '';

  private viewDetail(uid: string) {
    // this.$router.push({ name: 'ReservationBreakDownDetail', params: { clientUid: uid } });
    console.log(uid);
  }

  // Calendar
  private selectedDay: string | null = '';

  private datePickerVisible = false;

  @Prop()
  private calendarData = []; // 달력 일별 데이터

  private selectedMonth = moment().format('YYYY-MM');

  private handleChoiceDate(startDate: string, endDate: string) {
    this.selectedDay = startDate;
    this.listQuery.useStart = startDate;
    this.listQuery.useEnd = endDate;
  }

  private handleResetFormQuery() {
    this.totalSearchQuery = {
      searchValue: '',
      industryIdx: null,
      startDate: moment().format('YYYY-MM-DD'),
      endDate: moment().add(1, 'days').format('YYYY-MM-DD'),
      adult: 1,
      child: 0,
      page: 0,
      size: 2,
    };
    const calendarRef: any = this.$refs.calendar;
    if (calendarRef) calendarRef.removeActiveClass();
  }

  // 여기 어때요
  private aiItem = {
    stayList: [],
    restaurantList: [],
    placeList: [],
    leisureList: [],
    excitingList: [],
    productList: [],
  }

  // 여기 어때요
  private getAiItemList() {
    const lat = getLat();
    const lng = getLng();
    if (lat && lng) {
      this.totalSearchQuery.lat = lat;
      this.totalSearchQuery.lng = lng;
    } else {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.totalSearchQuery = {
            ...this.totalSearchQuery,
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          setLat(position.coords.latitude);
          setLng(position.coords.longitude);
        },
      );
    }
    this.aiSearchLoading = true;
    this.getAiStoreInfoList();
  }

  private getAiStoreInfoList() {
    getAiStoreInfoList({
      page: 0,
      size: 4,
      lat: this.totalSearchQuery.lat,
      lng: this.totalSearchQuery.lng,
    }).then((res) => {
      this.aiItem = {
        ...this.aiItem,
        ...res.data,
      };
      this.aiSearchLoading = false;
    });
  }

  private aiSearchLoading = false;

  private totalSearchQuery: any = {
    searchValue: '',
    industryIdx: null,
    startDate: this.$route.query.startDate ? this.$route.query.startDate : moment().format('YYYY-MM-DD'),
    endDate: this.$route.query.endDate ? this.$route.query.endDate : moment().add(1, 'days').format('YYYY-MM-DD'),
    adult: 1,
    child: 0,
  }

  private handleChangeMonth(yearMonth: any) {
    this.selectedMonth = moment(yearMonth).format('YYYY-MM');
    this.calendarData = [];
  }

  private getReservationList() {
    this.loading = true;
    getReservationClientList(this.listQuery).then((res) => {
      this.clientList = res.data.content;
      this.totalElements = res.data.totalElements;
      this.loading = false;
      this.getAiItemList(); // 여기 어때요
    });
  }

  private handleSearch() {
    this.totalSearchQuery = {
      ...this.totalSearchQuery,
      page: 1,
    };
    this.getReservationList();
  }

  private handleTotalSearch() {
    if (this.status === 0) this.listQuery.tabName = 'beforeUse';
    else if (this.status === 1) this.listQuery.tabName = 'afterUse';
    else if (this.status === -1) this.listQuery.tabName = 'cancel';
    this.$router.push({ name: 'mypageOrder', query: { ...this.listQuery } });
  }
  // Calendar

  private handleChangeDateRange() {
    if (this.dateRange && this.dateRange.length === 2) {
      this.listQuery.useStart = this.dateRange[0];
      this.listQuery.useEnd = this.dateRange[1];
    } else {
      this.listQuery.useStart = '';
      this.listQuery.useEnd = '';
    }
    this.handleSearch();
  }

  private cancelable(client: any) {
    return moment().isBefore(moment(client.useDate));
  }

  private handleCancel(client: any, index: number) {
    this.$confirm(`${client.storeName}(${client.useDate} ~ ${client.endDate}) ${client.industry}정보를 예약 취소하시겠습니까? (※업체 정책에 따라 취소 수수료가 발생할 수 있습니다.)`, '예약 취소', {
      cancelButtonText: '아니오',
      confirmButtonText: '예',
      type: 'warning',
    }).then(() => {
      cancelReservationClient(client.uid, {}).then(() => {
        this.$message.success('예약이 취소되었습니다.');
        this.clientList.splice(index, 1);
      });
    });
  }

  private handleClickSort(sort: string) {
    this.loading = true;
    this.clientList = [];
    this.filterSortDrawer = false;
    this.categorySortDrawer = false;
    this.listQuery.page = 0;
    this.listQuery.sort = sort;
    this.getReservationList();
  }

  private selectedIndustryName = '카테고리 전체';

  private handleClickIndustry(industry: any) {
    this.selectedIndustryName = industry.name;
    this.loading = true;
    this.filterSortDrawer = false;
    this.categorySortDrawer = false;
    this.listQuery.page = 0;
    this.listQuery.industryIdx = industry.idx;
    this.getReservationList();
  }

  private getSortLabel() {
    let label = '';
    const idx = this.sortList.findIndex((sort: any) => sort.value === this.listQuery.sort);
    if (idx > -1) label = this.sortList[idx].label;
    return label;
  }

  private isSeclect01Visible = false;

  private openSeclect01() {
    // console.log(`'isSeclect01Visible : ${this.isSeclect01Visible}'`);
    this.isSeclect01Visible = !this.isSeclect01Visible;
  }

  private closeModal() {
    this.isSeclect01Visible = false;
    this.handleTotalSearch();
  }

  private getReserveUrl(client: any) {
    if (client.industryIdx === 1) return `/stay/room/${client.itemUid}`;
    if (client.industryIdx === 2 || client.industryIdx === 3) return `/leisure/${client.storeIdx}`;
    if (client.industryIdx === 4) return { name: 'RestaurantView', params: { idx: client.storeIdx } };
    if (client.industryIdx === 5) return { name: 'GoodsView', params: { uid: client.itemUid } };
    if (client.industryIdx === 6) return { name: 'PlaceDetail', params: { idx: client.storeIdx, itemUid: client.itemUid } };
    return {};
  }

  private handleRetry(client: any) {
    const route: any = this.getReserveUrl(client);
    this.$router.push(route);
  }

  private getReserveInfo(client: any) {
    if (client.industryIdx === 1) return `${client.itemName} / 체크인 날짜:${client.useDate} / 체크아웃 날짜: ${client.endDate}`;
    if (client.industryIdx === 2 || client.industryIdx === 3) return `${client.itemName} / 예약날짜: ${client.useDate}`;
    // if (client.industryIdx === 4) return { name: 'RestaurantView', params: { idx: client.storeIdx } };
    // if (client.industryIdx === 5) return { name: 'GoodsView', params: { uid: client.itemUid } };
    // if (client.industryIdx === 6) return { name: 'PlaceDetail', params: { idx: client.storeIdx, itemUid: client.itemUid } };
    return {};
  }

  private handleKakaoShare(client: any) {
    const route: any = this.getReserveUrl(client);
    const baseUrl = process.env.VUE_APP_TARGET;
    const shareUrl = baseUrl + route;
    (window as any).Kakao.cleanup();
    (window as any).Kakao.init(process.env.VUE_APP_KAKAO_APP_KEY);
    (window as any).Kakao.Link.sendDefault({
      objectType: 'feed',
      content: {
        title: client.storeName,
        description: this.getReserveInfo(client),
        imageUrl: client.storeThumbUid ? `${baseUrl}/api/attached-file/${client.storeThumbUid}` : `${baseUrl}/favicon.ico`,
        link: {
          mobileWebUrl: shareUrl,
          webUrl: shareUrl,
        },
      },
      social: {
        likeCount: 0,
        commentCount: 0,
        sharedCount: 0,
      },
      buttons: [
        {
          title: `${client.industryIdx === 1 ? '객실 보러가기' : '보러가기'}`,
          link: {
            mobileWebUrl: shareUrl,
            webUrl: shareUrl,
          },
        },
      ],
    });
  }
}

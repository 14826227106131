
import {
  Component,
  Vue,
  Prop,
  Watch,
} from 'vue-property-decorator';
import { getReservationClientList } from '@/api/reservationClient';
import ClientList from './components/reservationClient.vue';
// import Calendar from '@/components/Calendar/index.vue';

@Component({
  components: {
    ClientList,
  },
})

export default class extends Vue {
  mounted() {
    window.addEventListener('resize', this.handleResize);
  }

  private activeTab = this.$route.query.tabName ? this.$route.query.tabName : 'beforeUse';

  private tabList = [
    { name: 'beforeUse', status: 0, label: '이용전' },
    { name: 'afterUse', status: 1, label: '이용후' },
    { name: 'cancel', status: -1, label: '취소' },
  ]

  private goBack() {
    this.$router.go(-1);
  }

  private windowWidth: number = window.innerWidth;

  private computeSize(): string {
    let str = '';
    if (this.windowWidth < 769) {
      str = 'mb_only';
    } else if (this.windowWidth >= 769 && this.windowWidth < 1024) {
      str = 'pc_only';
    } else {
      str = 'pc_only';
    }
    return str;
  }

  handleResize() {
    this.windowWidth = window.innerWidth;
  }

  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  }
}
